




















import { defineComponent } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import NavSidebarCategoriesList from '~/components/molecules/Navigation/NavSidebar/NavSidebarCategoriesList.vue';
import NavFooterLinks from '~/components/molecules/Navigation/NavSidebar/NavFooterLinks.vue';
import { useNavigationState } from '~/composables';
import CloseNavigation from '~/components/molecules/Navigation/CloseNavigation/CloseNavigation.vue';

export default defineComponent({
  name: 'NavSidebar',
  components: {
    NavSidebarCategoriesList,
    NavFooterLinks,
    CloseNavigation,
  },
  props: {
    firstLevelCategories: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const { toggleNavigation } = useNavigationState();

    return {
      toggleNavigation,
    };
  },
});
