















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { getNavSidebarFooterLinks } from '~/helpers/navigation/getNavSidebarFooterLinks';
import NavFooterLink from '~/components/molecules/Navigation/NavSidebar/NavFooterLink/NavFooterLink.vue';

export default defineComponent({
  name: 'NavFooterlinks',
  components: {
    NavFooterLink,
  },
  setup() {
    const navFooterLinks = computed(() => getNavSidebarFooterLinks());
    return {
      navFooterLinks,
    };
  },
});
