
<template>
  <span class="w-7 text-EXPONDO-primary text-3xl iconify svg--grid" />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AllCategoriesIndicator',
});
</script>
