<template>
  <span
    class="iconify svg--close text-xl"
    @click="toggleNavigation"
  />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useNavigationState } from '~/composables';

export default defineComponent({
  name: 'CloseNavigation',
  setup() {
    const { toggleNavigation } = useNavigationState();
    return {
      toggleNavigation,
    };
  },
});
</script>
