import { ROUTES } from '~/constants/routes';

export const getNavSidebarFooterLinks = () => {
  return [
    { title: 'My account', link: ROUTES.LOGIN },
    { title: 'Dispatch Information', link: ROUTES.DISPATCH_INFORMATION },
    { title: 'footer.Order tracking', link: ROUTES.MY_ORDERS },
    { title: 'footer.Premium Guarantee', link: ROUTES.PREMIUM_GUARANTEE },
    { title: 'footer.Inspirations', link: ROUTES.INSPIRATIONS, tag: 'Blog' },
  ];
};
