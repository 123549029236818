
























import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useNavigationState, useRouteExtended } from '~/composables';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import InfoTag from '~/components/atoms/InfoTag/InfoTag.vue';

export default defineComponent({
  name: 'NavFooterLink',
  components: {
    Link,
    InfoTag,
  },
  props: {
    url: {
      default: '',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    tag: {
      default: '',
      type: String,
    },
  },
  setup(props) {
    const { getAdjustedSlug } = useRouteExtended();
    const { toggleNavigation } = useNavigationState();
    const link = computed(() => getAdjustedSlug(props.url));
    const showLink = computed(() => !!(link.value && props.title));

    return {
      link,
      showLink,
      LinkType,
      toggleNavigation,
    };
  },
});
