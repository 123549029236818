





























































import { defineComponent } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { useVSFContext } from '@vue-storefront/core';
import type { PropType } from '@nuxtjs/composition-api';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import getCategoryIcon from '~/helpers/category/getCategoryIcon';
import { createBestsellingLink, createOnSaleLink } from '~/helpers/navigation/createNavLinks';
import NavigationLink from '~/components/molecules/Navigation/NavigationLink/NavigationLink.vue';
import AllCategoriesIndicator from
  '~/components/molecules/Navigation/AllCategoriesIndicator/AllCategoriesIndicator.vue';
import { useNavigationState } from '~/composables';
import { ROUTES_INFORMATION, ROUTES } from '~/constants/routes';

export default defineComponent({
  name: 'NavSidebarCategoriesList',
  components: {
    ImgView,
    NavigationLink,
    AllCategoriesIndicator,
  },
  props: {
    firstLevelCategories: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const { i18n } = useVSFContext();
    const { setFirstLevelCategory } = useNavigationState();
    const categoryOnSaleLink = createOnSaleLink();
    const bestsellersLink = createBestsellingLink();
    const shortcutLinkStyles = 'mt-2 block text-base font-bold cursor-pointer py-0.5 hover:bg-EXPONDO-grey-5';
    const onSaleButtonProps = {
      text: i18n.t('on-sale'),
      url: categoryOnSaleLink,
      class: shortcutLinkStyles,
    };
    const bestSellersLinkProps = {
      text: i18n.t('bestseller'),
      url: bestsellersLink,
      class: shortcutLinkStyles,
    };

    const itemClick = (slug: string) => {
      setFirstLevelCategory(slug);
    };

    const shopAllProductsProps = {
      text: i18n.t('all-products'),
      url: ROUTES_INFORMATION[ROUTES.SEARCH].SLUG,
    };

    return {
      getCategoryIcon,
      itemClick,
      onSaleButtonProps,
      shopAllProductsProps,
      bestSellersLinkProps,
      DEFAULT_CATEGORY_ICON: '/icons/categories/category.svg',
    };
  },
});
